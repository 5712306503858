import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSwipeable } from 'react-swipeable'
import Filter from './Filter'

interface ArtworkProps {
    id: string;
    class: string;
    title: string;
    year: string;
    material: string;
    dimension: string;
    availibility: string;
}

const Artwork: React.FC = () => {
    const history = useHistory()
    const url = process.env.PUBLIC_URL + '/art/artworks.json?v=' + process.env.REACT_APP_LAST_MODIFIED
    const [isArtworkPanelOpen, setIsArtworkPanelOpen] = React.useState(false)
    const [artworkId, setArtworkId] = React.useState('')
    const [isImageLoaded, setIsImageLoaded] = React.useState(false)
    const filterOptions = ['all','portrait','nature','abstract','nude']
    const filterAll = filterOptions[0]
    const localStorageFilter = localStorage.getItem('filter')
    const initialFilter = localStorageFilter ? localStorageFilter : filterAll
    const [selectedFilter, setSelectedFilter] = React.useState(initialFilter)
    const [shouldLoadArtworks, setShouldLoadArtworks] = React.useState(true)
    const [artworks, setArtworks] = React.useState([] as ArtworkProps[])

    React.useEffect(() => {
        if (shouldLoadArtworks) {
            fetch(url).then(r => r.json()).then(json => {
                setArtworks(json)
                setShouldLoadArtworks(false)
            })
        }

        const handlePopState = () => {
            setIsArtworkPanelOpen(false)
            setTimeout(() => {
                setArtworkId('')
                setIsImageLoaded(false)
            }, 300)
        }

        window.addEventListener('popstate', handlePopState)

        return () => {
            window.removeEventListener('popstate', handlePopState)
        }
    }, [shouldLoadArtworks, url])

    const handleThumbnailClick = (artworkId: string) => {
        setArtworkId(artworkId)
        setIsArtworkPanelOpen(!isArtworkPanelOpen)
        history.push(window.location.pathname)
    }

    const handleCloseClick = () => {
        setIsArtworkPanelOpen(false)
        setTimeout(() => {
            setArtworkId('')
            setIsImageLoaded(false)
        }, 300)
        history.goBack()
    }

    isArtworkPanelOpen ? document.body.style.overflowY = "hidden" : document.body.style.overflowY = "scroll"

    const swipeHandlers = useSwipeable({
        onSwipedRight: handleCloseClick
    });

    const renderArtwork = () => {
        const artwork = artworks.find(artwork => artwork.id === artworkId)

        return (
            <figure 
                id={artwork?.id} 
                className="m-artwork-item"
                {...swipeHandlers}
            >
                <div className="m-artwork-item-image">
                    <img 
                        className={isImageLoaded ? 'm-artwork-item-image--loaded' : 'm-artwork-item-image--none'}
                        alt={"Maria-Antonia, " + artwork?.title + ", " + artwork?.year}
                        src={"art/full/" + artwork?.id + ".jpg"}
                        onClick={handleCloseClick}
                        onLoad={() => setIsImageLoaded(true)}
                    />
                </div>
                <figcaption>
                    <h2>{artwork?.title}</h2>
                    <p>
                        {artwork?.year}<br />
                        {artwork?.material}<br />
                        {artwork?.dimension}<br />
                        {artwork && isArtworkAvailable(artwork)}
                    </p>
                </figcaption>
            </figure>
        )
    }

    const isArtworkAvailable = (artwork: ArtworkProps) => {
        if (artwork.availibility === 'available') {
            return (
                <a className="a-link--available" href={'mailto:available@maria-antonia.art?subject=' + 
                    artwork.title + 
                    '&body=' + 
                    artwork.title + 
                    '%0D%0A' + 
                    artwork.year + 
                    '%0D%0A' + 
                    artwork.material + 
                    '%0D%0A' + 
                    artwork.dimension
                    }>available
                </a>
            )
        }

        return artwork.availibility;
    }

    const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        localStorage.setItem('filter', event.currentTarget.value)
        setSelectedFilter(event.currentTarget.value)
    }

    return(
        <section className="m-artwork">
            <Filter 
                options={filterOptions} 
                selectedOption={selectedFilter}
                onChange={event => handleFilterChange(event)} 
            />
            <div className="m-thumbnails">
                {artworks.map((artwork, index) => 
                    (artwork.class === selectedFilter || selectedFilter === filterAll) ? (
                    <button 
                        key={"artwork" + index}
                        className={"filter " + artwork.class}
                        onClick={() => handleThumbnailClick(artwork.id)}
                    >
                        <img 
                            alt={artwork.title}
                            src={"art/thumbnails/" + artwork.id + ".jpg"} 
                        />
                    </button>) : null
                )}
            </div>
            <div className={`m-artwork-panel ${isArtworkPanelOpen ? 'm-artwork-panel--open' : 'm-artwork-panel--close'}`}>
                {renderArtwork()}
            </div>
        </section>
    )
}

export default Artwork