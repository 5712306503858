import React, { useContext } from 'react';
import { LangContext } from '../context/lang';
import Logo from '../components/Logo';
import SideMenu from '../components/Menu';
import FadeIn from 'react-fade-in';

interface ExhibitionsProps {
    date: string;
    description: string;
}

interface ExhibitionsPageProps {
  translate: (key: string) => string
}

const ExhibitionsPage: React.FC<ExhibitionsPageProps> = ({ translate }) => {
    const url = process.env.PUBLIC_URL + '/art/exhibitions.json?v=' + process.env.REACT_APP_LAST_MODIFIED
    const lang = useContext(LangContext)
    const className = 'a-text'
    const [shouldLoadExhibitions, setShouldLoadExhibitions] = React.useState(true)
    const [exhibitions, setExhibitions] = React.useState([] as ExhibitionsProps[])


    React.useEffect(() => {
        if (shouldLoadExhibitions) {
            fetch(url).then(r => r.json()).then(json => {
                setExhibitions(json)
                setShouldLoadExhibitions(false)
            })
        }
    })

    const renderExhibitions = (language: string) => (
        <div className={className}>
            <table>
                <tbody>
                    {// @ts-ignore
                    exhibitions[language]?.map((exhibition, index) => (
                        <tr key={index}>
                            <td>{exhibition.date}</td>
                            <td>{exhibition.description}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )

    switch (lang.state.language) {
        case 'EN':
        case 'DE':
        case 'FR':
            return (<div className="p-container">            
                        <SideMenu translate={translate} />
                        <Logo />
                        <FadeIn>
                            <h1 className="a-headline">
                                {translate('sideMenuExhibitions')}
                            </h1>
                            {renderExhibitions(lang.state.language)}
                        </FadeIn>
                    </div>)
        default:
            return null
    }
}

export default ExhibitionsPage